<!-- eslint-disable vue/component-tags-order -->
<template>
    <div class="p-5 bg-white rounded-lg">
        <h1 class="my-5 text-xl">新增社群回饋</h1>
        <el-form ref="formRefDom" :model="form" :rules="rules">
            <div class="flex">
                <el-form-item prop="reviewer_real_name" class="flex-1">
                    <label for="formName">回饋人暱稱</label>
                    <el-input
                        id="formName"
                        v-model="form.reviewer_real_name"
                    ></el-input>
                </el-form-item>
                <el-form-item prop="reviewed_at" class="flex-1 ml-5">
                    <label for="formCreatedAt" class="block">回饋人時間</label>
                    <el-date-picker
                        v-model="form.reviewed_at"
                        type="date"
                        placeholder="請選擇日期"
                        :picker-options="disabledDate"
                    />
                </el-form-item>
                <el-form-item prop="score" class="flex-1 ml-5">
                    <label class="block">評分</label>
                    <image-rating
                        :src="'img/icons/web/star-orange.svg'"
                        :rating="form.score"
                        :increment="1"
                        :fixed-points="2"
                        :spacing="0"
                        @rating-selected="ratingEvent"
                    ></image-rating>
                </el-form-item>
            </div>
            <el-form-item prop="comment">
                <label for="formComment">社群回饋內容</label>
                <el-input
                    v-model="form.comment"
                    type="textarea"
                    rows="5"
                    :maxlength="commentLimit"
                    show-word-limit
                ></el-input>
            </el-form-item>
        </el-form>
        <div class="border-b border-gray-100 h-[10px] w-full"></div>
        <div class="grid grid-cols-12 gap-4">
            <div class="col-span-3">
                <h3 class="my-5 text-xl">回饋人頭貼</h3>
                <AvatarUpload
                    ref="avatarUpload"
                    v-loading="loading"
                    :showTip="false"
                    :defaultImg="defaultAvatar"
                    :useDialog="true"
                    @onCropped="avatarCroppedData"
                />
            </div>
        </div>
        <div class="flex justify-center">
            <button
                v-permission="['update']"
                v-loading="loading"
                class="myDisabled py-2 px-2 min-w-[150px] rounded-lg border border-black text-black hover:bg-black hover:text-white duration-500"
                :disabled="loading"
                @click="
                    router.push({
                        name: 'provider_update',
                        params: {
                            id: route.params.userId,
                            tab: 'EvaluationList',
                        },
                    })
                "
            >
                返回列表
            </button>

            <button
                v-permission="['update']"
                v-loading="loading"
                class="myDisabled py-2 px-2 ml-5 min-w-[150px] rounded-lg border border-red-500 bg-red-500 text-white hover:bg-red-700 hover:text-white duration-500"
                :disabled="loading"
                @click="onSubmit"
            >
                儲存變更
            </button>
        </div>
    </div>
</template>
<script >
import { ref, reactive, del, set, computed, onActivated, onMounted, watch, getCurrentInstance, defineExpose, onBeforeUnmount, nextTick } from "@vue/composition-api";
import { useRoute, useRouter } from "vue2-helpers/vue-router";
// 圖片上傳組件
import AvatarUpload from "@/components/AvatarUpload.vue";
// 時間套件組件
import moment from "moment";
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const {
    proxy
  } = getCurrentInstance();
  const route = useRoute();
  const router = useRouter();
  const loading = ref(false);
  const formRefDom = ref(null);
  // 評論最大數字
  const commentLimit = ref(process.env.VUE_APP_INPUT_MIN_LIMIT);
  // 預設表單資料
  const defaultForm = {
    name: "",
    reviewed_at: "",
    comment: "",
    score: 5
  };
  const form = ref(defaultForm);
  const rules = ref({
    reviewer_real_name: [{
      required: true,
      message: "請輸入回饋人暱稱",
      trigger: "blur"
    }],
    reviewed_at: [{
      required: true,
      message: "請選擇評論時間",
      trigger: "blur"
    }],
    comment: [{
      required: true,
      message: "請輸入評論內容",
      trigger: "blur"
    }, {
      max: commentLimit.value,
      message: `評論最多 ${commentLimit.value} 個字`,
      trigger: "blur"
    }, {
      min: 1,
      message: `評論最少 1 個字`,
      trigger: "blur"
    }]
  });
  // 預設大頭照
  const defaultAvatar = ref("/img/avatar/avatar-icon.svg");
  // 圖片檔案
  const avatarFile = ref(null);
  /**
   * 非可選時間
   */
  const disabledDate = ref({
    disabledDate: time => {
      return time.getTime() > moment();
    }
  });

  // 點擊後的事件 選擇的星星 數值
  function ratingEvent(event) {
    form.value.score = event;
  }
  async function onSubmit() {
    try {
      await formRefDom.value.validate();
      await create();
    } catch (err) {
      proxy.$message({
        type: "error",
        message: "表單驗證失敗"
      });
    }
  }

  /**
   * 新增評論
   */
  async function create() {
    loading.value = true;
    const result = new FormData();
    Object.keys(form.value).forEach(objKey => {
      if (objKey === "reviewed_at") {
        result.append("reviewed_at", moment(form.value[objKey]).format("YYYY-MM-DD"));
      } else {
        result.append(objKey, form.value[objKey]);
      }
    });
    result.append("user_id", route.params.userId);
    // 判斷沒有傳送大頭照時出現
    if (avatarFile.value === null) {
      proxy.$message({
        type: "error",
        message: "請上傳大頭照"
      });
      loading.value = false;
      return;
    }
    result.append("avatar", avatarFile.value);
    try {
      await proxy.$api.AddCommentApi(result);
      proxy.$message({
        type: "success",
        message: "新增成功"
      });
      router.push({
        name: "provider_update",
        params: {
          id: route.params.userId,
          tab: "EvaluationList"
        }
      });
      reset();
    } catch (err) {
      proxy.$message({
        type: "error",
        message: "新增失敗"
      });
    } finally {
      loading.value = false;
    }
  }

  /**
   * 大頭照
   */
  /**
   * 裁切成功後回傳財切檔案
   * @param { type Blob(檔案) } val 裁切圖片檔
   */
  async function avatarCroppedData(val) {
    avatarFile.value = val;
  }
  /**
   * 重製資料
   */
  function reset() {
    form.value = defaultForm;
    avatarFile.value = null;
  }
  return {
    route,
    router,
    loading,
    formRefDom,
    commentLimit,
    form,
    rules,
    defaultAvatar,
    disabledDate,
    ratingEvent,
    onSubmit,
    avatarCroppedData
  };
};
__sfc_main.components = Object.assign({
  AvatarUpload
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss" scoped>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
    @apply w-full;
}

::v-deep .vue-rate-it-rating-text {
    @apply hidden;
}
</style>
